var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "w-100 grey lighten-4", attrs: { "fill-height": "" } },
    [
      _c(
        "main",
        { staticClass: "w-100 my-3 fill-height mx-auto" },
        [
          _c("v-container", { attrs: { fluid: "", "fill-height": "" } }, [
            _c(
              "section",
              {
                staticClass:
                  "w-100 d-flex align-items-center justify-content-center fill-height",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-sm-6 col-12 rounded-lg shadow-sm d-flex flex-column white fill-height",
                  },
                  [
                    _c("div", { staticClass: "mt-5 w-75 me-2" }, [
                      _c(
                        "span",
                        { staticClass: "f18 font-weight-bold d-block mt-3" },
                        [
                          _vm._v(
                            _vm._s(
                              this.$store.getters.clientType === "CLIENT"
                                ? _vm.$t("clint account")
                                : _vm.$t("doctor account")
                            ) + " "
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "mt-5" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "grey--text text--darken-1 font-weight-normal",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "The verification code will be sent to your mobile number"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row justify-content-between mt-auto right-to-left",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "w-75" },
                          [
                            _c("v-text-field", {
                              staticClass: "mx-1 text-center",
                              attrs: {
                                outlined: "",
                                type: "tel",
                                label: _vm.$t("Mobile number"),
                                rules: _vm.PhoneNumberRules,
                                required: "",
                              },
                              model: {
                                value: _vm.mobile,
                                callback: function ($$v) {
                                  _vm.mobile = $$v
                                },
                                expression: "mobile",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "w-25" },
                          [
                            _c("v-text-field", {
                              staticClass: "mx-1 text-center left-to-right",
                              attrs: {
                                outlined: "",
                                type: "tel",
                                rules: _vm.PrefixRules,
                                required: "",
                                readonly: "",
                              },
                              model: {
                                value: _vm.prenumber,
                                callback: function ($$v) {
                                  _vm.prenumber = $$v
                                },
                                expression: "prenumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "mt-1" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 d-flex flex-row align-items-stretch",
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "col-8 flex-fill mx-2 13",
                              attrs: {
                                color: "primary",
                                large: "",
                                loading: _vm.submit_loading,
                                disabled: _vm.submit_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.validate()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Confirm")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "col-4 text-white flex-fill mx-2 f13",
                              attrs: {
                                color: "red",
                                large: "",
                                loading: _vm.submit_loading,
                                disabled: _vm.submit_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.backToAuth()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Canceled")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }